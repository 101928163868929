






import AuthService from '@/services/AuthService'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Terms extends Vue {
  private terms: string = ''

  created() {
    AuthService.getTerms().then(res => {
      if (res.status === 200) {
        this.terms = res.data.content
      }
    })
  }
}
